import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationsReportsComponent } from './components/operations-reports/operations-reports.component';
import { OperationsPhotographiesComponent } from './components/operations-photographies/operations-photographies.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    OperationsReportsComponent,
    OperationsPhotographiesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class OperationsModule {}

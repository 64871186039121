import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faMagnifyingGlass, faWindowMaximize, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { AppsUtils } from '@lightning/configuration';
import { OperationReportPreview, OperationsReportsSearchRequest, OperationsReportsSearchResponse } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { KeyValuePipe, OverlayService } from '@lightning/wild-ui';
import { ModalConfirmComponent } from 'apps/backoffice/src/app/shared/components/modal-confirm/modal-confirm.component';
import { TableLine } from 'apps/backoffice/src/app/shared/models/table-line.types';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-operations-reports',
    templateUrl: './operations-reports.component.html',
    styleUrls: ['./operations-reports.component.scss'],
})
export class OperationsReportsComponent implements OnInit {

    public icons = {
        search: faMagnifyingGlass,
        view: faWindowMaximize,
        delete: faXmarkCircle
    };

    public lines: Array<TableLine<OperationReportPreview>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private keyValuePipe: KeyValuePipe) { }

    ngOnInit(): void {

        this.filters = this.route.snapshot.queryParams;

        this.load(false);
    }

    public load(next: boolean): void {

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request: OperationsReportsSearchRequest = {
            index: next ? this.lines.length : 0,
            limit: 50,
            ...this.filters
        }

        this.onlineService
            .searchOperationsReports(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: OperationsReportsSearchResponse) => {

                    const lines: Array<TableLine<OperationReportPreview>> = response.items.map((data) => {
                        return {
                            isSelected: false,
                            data
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        // let options: FiltersOptions = {
        //     description: 'search by ' + filter,
        //     type: 'text',
        //     choices: [],
        //     value: this.filters[filter]
        // };

        // switch(filter) {

        //     case 'email':
        //         options.type = 'email';
        //     break;

        //     case 'roles':
        //         options.choices = this.keyValuePipe.transform(AccountRoles);
        //     break;

        //     case 'status':
        //         options.choices = this.keyValuePipe.transform(AccountStatus);
        //     break;
        // }

        // // Selection modal
        // let value: string | undefined = await this.overlayService.openModal({
        //     component: ModalTableFilterComponent,
        //     inputs: {
        //         options
        //     }
        // });

        // // Canceled
        // if(value === undefined) {
        //     return;
        // }

        // if(value === '') {
        //     delete this.filters[filter];
        // } else {
        //     this.filters[filter] = value;
        // }

        // this.load(false);

        // Update the url according the filter
        // this.router.navigate([], { queryParams: this.filters });
    }

    public edit(id: string): void {
        return;
    }

    public view(id: string) {
        window.open(`${AppsUtils.resolveAppUrl('website')}/operations/reports/${id}`, `_blank`);
    }

    public async delete(id: string) {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: `Are you sure you want to remove the operation report ${id} and all it's data?`
            }})) {

            this.onlineService.deleteOperationReport(id).subscribe({
                next: () => {
                    this.lines = this.lines.filter(line => line.data.id !== id);
                }
            })
        }

    }



}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringToColorUtils } from '@lightning/backoffice/app/shared/utils/string-to-color.utils';
import { AccountStatus, Dashboard, OperatorStatus, StoreOrderStatus } from '@lightning/lightning-definitions';
import { GraphBarItem } from '@lightning/wild-ui';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public AccountStatus = AccountStatus;
    public OperatorStatus = OperatorStatus;
    public StoreOrderStatus = StoreOrderStatus;

    public dashboard = new Dashboard();
    public operatorsStatusGraphItems = new Array<GraphBarItem>();
    public operatorsLegendsGraphItems = new Array<GraphBarItem>();
    public operationsStatusGraphItems = new Array<GraphBarItem>();
    public storeOrdersStatusGraphItems = new Array<GraphBarItem>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {


        this.activatedRoute.data.subscribe({
            next: ({ dashboard }) => {

                if (!dashboard) {
                    return;
                }

                this.dashboard = dashboard;

                // Fixed
                this.operatorsStatusGraphItems = [
                    {
                        name: OperatorStatus.Idle,
                        color: getComputedStyle(document.body).getPropertyValue('--color-operators-status-iddle'),
                        value: this.dashboard.operators.statuses[OperatorStatus.Idle]
                    },
                    {
                        name: OperatorStatus.Active,
                        color: getComputedStyle(document.body).getPropertyValue('--color-operators-status-active'),
                        value: this.dashboard.operators.statuses[OperatorStatus.Active]
                    },
                    {
                        name: OperatorStatus.Classified,
                        color: getComputedStyle(document.body).getPropertyValue('--color-operators-status-classified'),
                        value: this.dashboard.operators.statuses[OperatorStatus.Classified]
                    },
                ];

                // Fixed
                this.operatorsLegendsGraphItems = [
                    {
                        name: 'regular',
                        color: getComputedStyle(document.body).getPropertyValue(`--color-background-3`),
                        value: this.dashboard.operators.total - this.dashboard.operators.legends
                    },
                    {
                        name: 'legend',
                        color: getComputedStyle(document.body).getPropertyValue(`--color-background-6`),
                        value: this.dashboard.operators.legends
                    },
                ];

                // Dynamic
                for (const mode in this.dashboard.operations.reports.modes) {
                    if (Object.prototype.hasOwnProperty.call(this.dashboard.operations.reports.modes, mode)) {

                        this.operationsStatusGraphItems.push({
                            name: mode,
                            color: StringToColorUtils.Convert(mode),
                            value: this.dashboard.operations.reports.modes[mode]
                        });
                    }
                }

                // Fixed
                this.storeOrdersStatusGraphItems = [
                    // {
                    //     name: StoreOrderStatus.Canceled,
                    //     color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.Canceled}`),
                    //     value: this.dashboard.store.orders.statuses[StoreOrderStatus.Canceled]
                    // },
                    {
                        name: StoreOrderStatus.Unpaid,
                        color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.Unpaid}`),
                        value: this.dashboard.store.orders.statuses[StoreOrderStatus.Unpaid]
                    },
                    {
                        name: StoreOrderStatus.PaymentInProgress,
                        color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.PaymentInProgress}`),
                        value: this.dashboard.store.orders.statuses[StoreOrderStatus.PaymentInProgress]
                    },
                    {
                        name: StoreOrderStatus.PaymentFailed,
                        color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.PaymentFailed}`),
                        value: this.dashboard.store.orders.statuses[StoreOrderStatus.PaymentFailed]
                    },
                    {
                        name: StoreOrderStatus.Paid,
                        color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.Paid}`),
                        value: this.dashboard.store.orders.statuses[StoreOrderStatus.Paid]
                    },
                    {
                        name: StoreOrderStatus.ReadyForShipping,
                        color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.ReadyForShipping}`),
                        value: this.dashboard.store.orders.statuses[StoreOrderStatus.ReadyForShipping]
                    },
                    // {
                    //     name: StoreOrderStatus.Shipped,
                    //     color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.Shipped}`),
                    //     value: this.dashboard.store.orders.statuses[StoreOrderStatus.Shipped]
                    // },
                    // {
                    //     name: StoreOrderStatus.Complete,
                    //     color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.Complete}`),
                    //     value: this.dashboard.store.orders.statuses[StoreOrderStatus.Complete]
                    // },
                    {
                        name: StoreOrderStatus.PayOnDelivery,
                        color: getComputedStyle(document.body).getPropertyValue(`--color-store-orders-status-${StoreOrderStatus.PayOnDelivery}`),
                        value: this.dashboard.store.orders.statuses[StoreOrderStatus.PayOnDelivery]
                    },
                ];
            }
        });

    }

    public clickOperatorStatusGraphItem(item: GraphBarItem): void {

        this.router.navigate(['operators'], { queryParams: { status: item.name }});
    }

    public clickOperatorLegendGraphItem(item: GraphBarItem): void {

        this.router.navigate(['operators'], { queryParams: { isLegend: item.name === 'legend' }});
    }

    public clickOperationReportModeGraphItem(item: GraphBarItem): void {

        this.router.navigate(['operations', 'reports'], { queryParams: { mode: item.name }});
    }

    public clickStoreOrderStatusGraphItem(item: GraphBarItem): void {

        this.router.navigate(['store/orders'], { queryParams: { status: item.name }});
    }

}

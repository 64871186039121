export class StringToColorUtils {

    public static Convert(text: string, options?: { brightness: 'light' | 'dark' }): string {

        if (text.length < 6) {
            text = text + text + text; // Helps to generate different colors with very short texts
        }

        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }

        let colour = '#';
        for (let i = 0; i < 3; i++) {

            let value = (hash >> (i * 8)) & 0xFF;

            if (options?.brightness === 'light' && value < 0x80) {
                value = 0xFF - value;
            } else if (options?.brightness === 'dark' && value > 0x80) {
                value = value - 0x80;
            }

            colour += value.toString(16).padEnd(2, '0');
        }

        return colour;
    }

}



<wui-cards>

    <wui-card
        title="Operator tag"
        description="Program & register it"
        logo="assets/products/nfcTagOperator.svg"
        (click)="createNfcTagOperator()">
    </wui-card>

    <wui-card
        title="Guest tag"
        logo="assets/products/nfcTagGuest.svg"
        description="Program & register it"
        (click)="createNfcTagGuest()">
    </wui-card>

    <wui-card
        title="Resources tag"
        logo="assets/products/nfcTagResource.svg"
        description="Program & register it"
        (click)="createNfcTagResource()">
    </wui-card>

    <wui-card
        title="Parts tag"
        logo="assets/products/nfcTagPart.svg"
        description="Program & register it"
        (click)="createNfcTagPart()">
    </wui-card>

    <wui-card
        title="Location tag"
        logo="/assets/products/nfcTagLocation.svg"
        description="Program & register it"
        (click)="createNfcTagLocation()">
    </wui-card>

    <wui-card
        title="System tag"
        logo="/assets/products/nfcTagSystem.svg"
        description="Program & register it"
        (click)="createNfcTagSystem()">
    </wui-card>

    <wui-card
        title="Headquarter gateway"
        logo="assets/products/groundGateway.svg"
        description="Register it"
        [wuiDisabled]="true">
    </wui-card>

    <wui-card
        title="Ground module"
        logo="assets/products/groundModule.svg"
        description="Register it"
        (click)="createGroundModule()">
    </wui-card>

</wui-cards>


import { Default, IsMongoId, IsNumber, IsOptional, Max, Min } from '@lightning/decorators';
import { DeviceRegistration } from '../entities/device.entity';
import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { Expose, Transform } from 'class-transformer';
import { NfcTagsModels, NfcTagsSystemActions } from '../enums/nfc-tags.enums';

export class DevicesRegistrationsSearchRequest extends PaginedRequest {

    @Expose()
    @IsOptional()
    @IsMongoId()
    public id?: string;

    @Expose()
    @IsOptional()
    public created?: string;

    @Expose()
    @IsOptional()
    public serial?: string;

    @Expose()
    @IsOptional()
    public product?: string;

    @Expose()
    @IsOptional()
    public model?: string;

    @Expose()
    @IsOptional()
    public orderId?: string;

    @Expose()
    @IsOptional()
    @Default(-1)    // Latest first by default
    @Transform(({ value }) => Number(value))
    @IsNumber()
    @Min(-1)
    @Max(1)
    public sortByDate?: number;
}

export class DevicesRegistrationsSearchResponse extends PaginedResponse {

    public items!: Array<DeviceRegistration>;
}

class DeviceCreateNfcRequest {

    public serial = '';

    public model: NfcTagsModels = NfcTagsModels.Card;

    public version = '1.0.0';

}

export class DeviceCreateNfcTagOperatorRequest extends DeviceCreateNfcRequest {

    public lifeTime = 1;

    public accountId = '';

    public operatorName = '';
}

export class DeviceCreateNfcTagGuestRequest extends DeviceCreateNfcRequest{

    public number = 1;
}

export class DeviceCreateNfcTagResourceRequest extends DeviceCreateNfcRequest {

    public value = 1000;
}

export class DeviceCreateNfcTagPartRequest extends DeviceCreateNfcRequest {

    public number = 1;
}

export class DeviceCreateNfcTagLocationRequest extends DeviceCreateNfcRequest {

    public location = ''; // GroundLocations
}

export class DeviceCreateNfcTagSystemRequest extends DeviceCreateNfcRequest {

    public action = NfcTagsSystemActions.Update;
}

<wui-modal-layout>

    <!-- <div title>{{ title }}</div> -->

    <div body>

        <wui-entry>
            <div name>{{ options.description }}</div>
            <ng-container value>
                <wui-input *ngIf="options.choices.length === 0" [(value)]="options.value" [type]="options.type"></wui-input>
                <wui-select *ngIf="options.choices.length > 0" [(value)]="options.value">
                    <option *ngFor="let choice of options.choices" [value]="choice.value">{{ choice.key }}</option>
                </wui-select>
            </ng-container>
        </wui-entry>

    </div>

    <wui-buttons footer>
        <wui-button (click)="clear()">clear</wui-button>
        <wui-button (click)="confirm()">confirm</wui-button>
    </wui-buttons>

</wui-modal-layout>

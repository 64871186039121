<wui-modal-layout>

    <!-- <div title>{{ title }}</div> -->

    <div body>{{ description }}</div>

    <wui-buttons footer>
        <wui-button (click)="cancel()">cancel</wui-button>
        <wui-button (click)="confirm()">confirm</wui-button>
    </wui-buttons>

</wui-modal-layout>

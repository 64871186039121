export class StringUtils {

    public static capitalize(value: string): string {

        // Todo, manage case like ¡spannish!
        // Todo, manage multiquotes texts!

        if (!value) {
            return '';
        }

        return value[0].toUpperCase() + value.slice(1); //.toLocaleLowerCase();
    }

    public static uncapitalize(value: string): string {

        // Usefull for SomeThing -> someThing

        if (!value) {
            return '';
        }

        return value[0].toLowerCase() + value.slice(1);
    }

}
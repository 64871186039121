import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { StoreOrdersComponent } from './components/store-orders/store-orders.component';
import { StoreOrdersEditComponent } from './components/store-orders-edit/store-orders-edit.component';

@NgModule({
    declarations: [
        StoreOrdersComponent,
        StoreOrdersEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
})
export class StoreModule { }

<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="description">
            <wui-entry>
                <div name>Id</div>
                <div value>
                    {{ order.id }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Date</div>
                <div value>
                    {{ order.date | date }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Status</div>
                <!-- <div value [style.color]="'var(--color-store-orders-status-' + (order.status | uncapitalize ) + ')'">
                    {{ order.status }}
                </div> -->
                <wui-select value [value]="order.status" #status
                    (valueChange)="updateStatus(status.value)"
                    [required]="true"
                    [style.color]="'var(--color-store-orders-status-' + (order.status | uncapitalize ) + ')'">
                    <option *ngFor="let status of Status | keyValue"
                        [value]="status.value"
                        [style.color]="'var(--color-store-orders-status-' + (status.value | uncapitalize ) + ')'">
                            {{ status.key }}
                    </option>
                </wui-select>
            </wui-entry>
        </wui-section>

        <wui-section name="items">

            <wui-entry *ngFor="let item of order.items">
                <div name>{{ item.name }} x {{ item.quantity }}</div>

                <div value>
                    <wui-json [value]="item.options"></wui-json>

                    <!-- Shortcut to create the operator tag -->
                    <wui-button *ngIf="item.name === Products.NfcTagOperator" (click)="createNfcTagOperator(item)">
                        create
                    </wui-button>

                </div>

            </wui-entry>

        </wui-section>

        <wui-section name="address">
            <wui-entry>
                <div name>{{ 'shared.firstName' | translate | capitalize }}</div>
                <div value>{{ order.address.firstName }}</div>
            </wui-entry>
            <wui-entry>
                <div name>{{ 'shared.lastName' | translate | capitalize }}</div>
                <div value>{{ order.address.lastName }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.street' | translate | capitalize }}</div>
                <div value>{{ order.address.street }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.postalCode' | translate | capitalize }}</div>
                <div value>{{ order.address.postalCode }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.city' | translate | capitalize }}</div>
                <div value>{{ order.address.city }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.country' | translate | capitalize }}</div>
                <div value>{{ order.address.country }}</div>
            </wui-entry>

            <!-- <wui-entry>
                <div name></div>
                <wui-button value (click)="orderService.editAddress">{{ 'shared.edit' | translate }}</wui-button>
            </wui-entry>

            <wui-entry>
                <div name></div>
                <wui-button value (click)="orderService.removeAddress(address)">{{ 'shared.remove' | translate }}</wui-button>
            </wui-entry> -->

        </wui-section>

        <wui-section name="raw">
            <wui-json [value]="order"></wui-json>
        </wui-section>

    </div>



    <wui-buttons footer>
        <wui-button (click)="cancel()">close</wui-button>
        <!-- <wui-button (click)="confirm()" [wuiDisabled]="isSending">confirm</wui-button> -->
    </wui-buttons>

</wui-modal-layout>

<wui-modal-layout [wuiDisabled]="isLoading">

    <div title>{{ title }}</div>

    <app-header header logo="assets/products/nfcTagOperatorDark.svg" [description]="header"></app-header> <!-- TODO ADD A COLOR OPTION, MOVE TO WUI ?-->

    <!-- Instructions -------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'instructions'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="instructions">
            Please follow these instructions:
            <ul>
                <li>You have to know the id of the account of the operator.</li>
                <li>Take the right model of a brand new (never registered) operator tag.</li>
                <li>Put it on the work board.</li>
                <li>Press Continue.</li>
            </ul>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'instructions'">
        <wui-button (click)="close()">cancel</wui-button>
        <wui-button (click)="readNfcTagUid(); continue()">continue</wui-button>
    </wui-buttons>

    <!-- Serial ------------------------------------------------------------------------------------------------>

    <div body *ngIf="tabs.selected?.selector === 'serial'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="tag">
            <wui-entry>
                <div name>Serial</div>
                <wui-input value [(value)]="request.serial" [required]="true" pattern="([0-9 A-F]{2}[:]){6}([0-9 A-F]{2})" (click)="readNfcTagUid()"></wui-input>
                <div detail>Press 'read serial' to autofill this field.</div>
            </wui-entry>
            <wui-entry>
                <div name>Model</div>
                <wui-select value [(value)]="request.model" [required]="true">
                    <option *ngFor="let model of NfcTagsModels | keyValue" [value]="model.value">{{ model.key }}</option>
                </wui-select>
            </wui-entry>
            <wui-entry>
                <div name>Version</div>
                <wui-input value [(value)]="request.version" [required]="true"></wui-input>
            </wui-entry>
            <wui-entry>
                <div name>Life time</div>
                <wui-input value [(value)]="request.lifeTime" type="number" [min]="1" [required]="true"></wui-input>
                <div detail>Validity in years.</div>
            </wui-entry>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'serial'">
        <wui-button (click)="back()">back</wui-button>
        <wui-button (click)="continue()" [wuiDisabled]="!request.serial || !request.model || !request.version">continue</wui-button>
    </wui-buttons>



    <!-- Account ----------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'account'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="account">
            <wui-entry>
                <div name>Id</div>
                <wui-input value
                    [(value)]="request.accountId"
                    (valueChange)="accountIdChanged()"
                    pattern="^[0-9a-f]{24}$"
                    [required]="true">
                </wui-input>
                <div detail *ngIf="request.accountId">
                    <ng-container *ngIf="account">
                        <ng-container *ngIf="operatorProfile === undefined">
                            This account doesn't have an operator profile yet.
                        </ng-container>
                        <ng-container *ngIf="operatorProfile">
                            This account already have an operator profile!
                        </ng-container>
                    </ng-container>
                </div>
            </wui-entry>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'account'">
        <wui-button
            (click)="back()">
            back
        </wui-button>
        <wui-button
            (click)="continue()"
            [wuiDisabled]="!request.accountId">
            continue
        </wui-button>
    </wui-buttons>



    <!-- Operator ----------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'operator'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section [name]="operatorProfile ? 'operator ' + operatorProfile.number : 'new operator'">

            <ng-container *ngIf="operatorProfile">

                <wui-entry>
                    <div name>Number</div>
                    <div value>{{ operatorProfile.number }}</div>
                </wui-entry>
                <wui-entry>
                    <div name>Status</div>
                    <div value>{{ operatorProfile.status }}</div>
                </wui-entry>
                <wui-entry>
                    <div name>Expiration</div>
                    <div value>{{ operatorProfile.expiration | date }}</div>
                </wui-entry>

            </ng-container>

            <wui-entry>
                <div name>Name</div>
                <wui-input value [(value)]="request.operatorName" [required]="true"></wui-input>
                <div detail *ngIf="operatorProfile && operatorProfile.name !== request.operatorName">
                    Warning: You modified this option from an existing operator.
                </div>
            </wui-entry>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'operator'">
        <wui-button
            (click)="back()">
            back
        </wui-button>
        <wui-button
            *ngIf="operatorProfile === undefined"
            (click)="createOperatorProfile()"
            [wuiDisabled]="!request.operatorName">
            create operator profile
        </wui-button>
        <wui-button
            *ngIf="operatorProfile !== undefined"
            (click)="createNfcTagAndContinue()"
            [wuiDisabled]="operatorProfile === undefined">
            continue
        </wui-button>
    </wui-buttons>


    <!-- Write -------------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'write'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="write">
            <wui-entry *ngIf="nfcTag">
                <div name>Records</div>
                <div value>
                    <div *ngFor="let record of nfcTag.records" class="record">{{ record }}</div>
                </div>
            </wui-entry>

        </wui-section>
    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'write'">
        <wui-button [wuiDisabled]="true">
            back
        </wui-button>
        <wui-button (click)="writeNfcTagAndContinue()">
            write
        </wui-button>

    </wui-buttons>


    <!-- Complete ----------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'complete'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="complete">
            The tag is ready to use and registered as device id {{ nfcTag?.id }}.
        </wui-section>
    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'complete'">
        <wui-button (click)="close()">
            close
        </wui-button>
    </wui-buttons>

</wui-modal-layout>

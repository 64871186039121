<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <!-- <td>
                <input type="checkbox" (change)="selectAll($event)">
            </td> -->

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                account id
                <fa-icon
                    [class.active]="filters['accountId'] !== undefined"
                    (click)="searchBy('accountId')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                date
                <fa-icon
                    [class.active]="filters['date'] !== undefined"
                    (click)="searchBy('date')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                items
            </td>

            <td>
                amount
            </td>

            <td>
                status
                <fa-icon
                    [class.active]="filters['status'] !== undefined"
                    (click)="searchBy('status')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                actions
            </td>
        </tr>

        <tr *ngFor="let line of lines; let i = index" (click)="edit(line.data)">

            <!-- <td>
                <input type="checkbox" [checked]="line.isSelected" (click)="select(line, $event)">
            </td> -->

            <td>{{ i + 1 }}</td>

            <td>{{ line.data.id }}</td>

            <td (click)="editAccount(line.data.accountId); $event.stopPropagation()">{{ line.data.accountId }}</td>

            <td>{{ line.data.date | date }}</td>

            <td>{{ line.data.items.length }}</td>

            <td>{{ line.data.amount }}</td>

            <td [style.color]="'var(--color-store-orders-status-' + (line.data.status | uncapitalize ) + ')'">{{ line.data.status }}</td> <!-- account status css to define -->

            <td wuiStopPropagation>
                <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
            </td>

        </tr>

    </table>

</div>

import { Component, Input } from '@angular/core';
import { LaserService } from '@lightning/backoffice/app/shared/services/laser/laser.service';
import { WorkBoardService } from '@lightning/backoffice/app/shared/services/work-board/work-board.service';
import { Account, DeviceCreateNfcTagOperatorRequest, DeviceNfcTags, OperatorProfile, OperatorsCreateRequest, Products } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService, StringUtils, Tabs } from '@lightning/wild-ui';
import { finalize, forkJoin, of, switchMap } from 'rxjs';

@Component({
    selector: 'app-devices-nfc-tag-operator-create',
    templateUrl: './devices-nfc-tag-operator-create.component.html',
    styleUrls: ['./devices-nfc-tag-operator-create.component.scss'],
})
export class DevicesNfcTagOperatorCreateComponent {

    @Input()
    public resolve = () => Function;

    @Input()
    public title = '';

    @Input()
    public set accountId(accountId: string) {
        this.request.accountId = accountId;
    }

    @Input()
    public set operatorName(operatorName: string) {
        this.request.operatorName = operatorName;
    }

    public tabs: Tabs = {
        items: [
            { selector: 'instructions', text: 'instructions' },
            { selector: 'serial',       text: 'serial' },
            { selector: 'account',      text: 'account' },
            { selector: 'operator',     text: 'operator' },
            { selector: 'write',        text: 'write' },
            { selector: 'complete',     text: 'complete' },
        ]};

    public request = new DeviceCreateNfcTagOperatorRequest();

    public isLoading = false;

    public account: Account | undefined;
    public operatorProfile: OperatorProfile | undefined;
    public nfcTag: DeviceNfcTags | undefined;

    public constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private workBoardService: WorkBoardService,
        private laserService: LaserService) {

            this.tabs.selected = this.tabs.items[0];

            this.request.version = '1.0.0';
        }

    public getLaserMarkingProject(): void {

        this.laserService.getLaserMarkingProject(StringUtils.toKebabCase(Products.NfcTagOperator), this.operatorProfile);
    }

    public back(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex <= 0) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex - 1];
    }

    public continue(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex >= this.tabs.items.length) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex + 1];
    }

    public get header(): string {

        let text = '';

        if (this.request.serial) {
            text += `Serial ${this.request.serial}.\n`;
        }

        if (this.account) {
            text += `\nAccount ${this.account.id} : ${this.account.email}.`;
        }

        if (this.operatorProfile) {
            text += `\nOperator profile ${this.operatorProfile.number} : ${this.operatorProfile.name}.`;
        }

        return text || `New operator tag`;
    }

    public async readNfcTagUid(): Promise<void> {

        if (!this.workBoardService.isConnected) {

            await this.workBoardService.connect();
        }

        // Form autofilling
        this.request.serial = (await this.workBoardService.readNfcTagUid()).uid;
    }

    public accountIdChanged(): void {

        if(!this.request.accountId) {
            return;
        }

        this.onlineService.getAccount(this.request.accountId)
            .pipe(switchMap((account) => {
                return forkJoin({
                    account: of(account),
                    operatorProfile: account.operatorId ? this.onlineService.getOperator(account.operatorId || '') : of(undefined)
                });
            }))
            .subscribe({
                next: ({ account, operatorProfile }) => {

                    this.account = account;
                    this.operatorProfile = operatorProfile;

                    if (!operatorProfile) {
                        return;
                    }

                    // Form autofilling
                    this.request.operatorName = operatorProfile.name || '';
                }
            });
    }


    public createOperatorProfile(): void {

        if(!this.request.operatorName) {
            return;
        }

        const request: OperatorsCreateRequest = {
            accountId: this.request.accountId,
            name: this.request.operatorName,
            isLegend: false
        };

        this.isLoading = true;

        this.onlineService.createOperator(request)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: operatorProfile => {

                    this.operatorProfile = operatorProfile;

                    this.overlayService.openNotification({
                        message: `A new operator profile has been created for OPR-${operatorProfile.number}: ${operatorProfile.name}`,
                        type: 'success'
                    });
                }
        });
    }


    public createNfcTagAndContinue(): void {

        this.isLoading = true;

        this.onlineService.createDeviceNfcTagOperator(this.request)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: nfcTag => {

                    this.nfcTag = nfcTag;

                    this.continue();
                }
        });
    }

    public async writeNfcTagAndContinue(): Promise<void> {

        if (!this.nfcTag) {
            return;
        }

        this.isLoading = true;

        try {

            await this.workBoardService.writeNfcTag(this.nfcTag.serial, this.nfcTag.records);

            this.overlayService.openNotification({
                message: `The tag of the operator OPR-${this.operatorProfile?.number}: ${this.operatorProfile?.name} is now ready to use!`,
                type: 'success'
            });

            // TODO: Request the backend to inform the tag was written, without that, the process is not totally complete (write failure)

            this.continue();

        } catch(exception) {

            this.overlayService.openNotification({
                message: exception as string,
                type: 'error'
            });
        }

        this.isLoading = false;
    }

    public close(): void {

        this.resolve();

        this.overlayService.closeModal();
    }

}

<input
    [(ngModel)]="realValue"
    [class.number]="type === 'number'"
    [class.checkbox]="type === 'checkbox'"

    [placeholder]="placeholder"
    [autocomplete]="autocomplete"

    [type]="type"
    [min]="min"
    [max]="max"

    [checked]="realValue === true"
    #input
    (change)="onChange(input.checked)"
    (keyup)="onKeyUp(input.value)"
    (click)="onKeyUp(input.value)"
    (paste)="onChange($event)"

    list="suggestions"
    >

    <datalist id="suggestions">
        @for (suggestion of suggestions; track suggestion) {
            <option [value]="suggestion">{{ suggestion }}</option>
        }
    </datalist>

<div class="invalidValue" *ngIf="isInvalid">⚠</div>

<wui-modal-layout [wuiDisabled]="isLoading">

    <div title>{{ title }}</div>

    <app-header header logo="assets/products/nfcTagGuestDark.svg" [description]="header"></app-header> <!-- TODO ADD A COLOR OPTION, MOVE TO WUI ?-->

    <!-- Instructions -------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'instructions'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="instructions">
            Please follow these instructions:
            <ul>
                <li>Take a brand new (never registered) guest tag.</li>
                <li>Put it on the work board.</li>
                <li>Press Continue.</li>
            </ul>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'instructions'">
        <wui-button (click)="close()">cancel</wui-button>
        <wui-button (click)="readNfcTagUid(); continue()">continue</wui-button>
    </wui-buttons>

    <!-- Serial ------------------------------------------------------------------------------------------------>

    <div body *ngIf="tabs.selected?.selector === 'serial'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="tag">
            <wui-entry>
                <div name>Serial</div>
                <wui-input value [(value)]="request.serial" [required]="true" pattern="([0-9 A-F]{2}[:]){6}([0-9 A-F]{2})" (click)="readNfcTagUid()"></wui-input>
                <div detail>Press 'read serial' to autofill this field.</div>
            </wui-entry>
            <wui-entry>
                <div name>Model</div>
                <wui-select value [(value)]="request.model" [required]="true">
                    <option *ngFor="let model of NfcTagsModels | keyValue" [value]="model.value">{{ model.key }}</option>
                </wui-select>
            </wui-entry>
            <wui-entry>
                <div name>Version</div>
                <wui-input value [(value)]="request.version" [required]="true"></wui-input>
            </wui-entry>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'serial'">
        <wui-button (click)="back()">back</wui-button>
        <wui-button (click)="continue()" [wuiDisabled]="!request.serial || !request.version">continue</wui-button>
    </wui-buttons>


    <!-- Guest ----------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'guest'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="guest">
            <wui-entry>
                <div name>Number</div>
                <wui-input value [(value)]="request.number" type="number" [min]="1" [required]="true"></wui-input>
            </wui-entry>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'guest'">
        <wui-button
            (click)="back()">
            back
        </wui-button>
        <wui-button
            (click)="createNfcTagAndContinue()"
            [wuiDisabled]="!request.number">
            continue
        </wui-button>
    </wui-buttons>


    <!-- Write --------------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'write'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="write">
            <wui-entry *ngIf="nfcTag">
                <div name>Records</div>
                <div value>
                    <div *ngFor="let record of nfcTag.records" class="record">{{ record }}</div>
                </div>
            </wui-entry>

        </wui-section>
    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'write'">
        <wui-button [wuiDisabled]="true">
            back
        </wui-button>
        <wui-button (click)="writeNfcTagAndContinue()">
            write
        </wui-button>

    </wui-buttons>


    <!-- Complete ----------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'complete'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="complete">
            The tag is ready to use and registered as device id {{ nfcTag?.id }}.
        </wui-section>
    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'complete'">
        <!-- <wui-button (click)="again()">
            again
        </wui-button> -->
        <wui-button (click)="close()">
            close
        </wui-button>
        <wui-button (click)="next()">
            next one ({{ this.request.number + 1 }})
        </wui-button>
    </wui-buttons>

</wui-modal-layout>

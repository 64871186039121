import { Component, Input } from '@angular/core';
import { WorkBoardService } from '@lightning/backoffice/app/shared/services/work-board/work-board.service';
import { DeviceCreateNfcTagLocationRequest, DeviceNfcTags, GroundLocations } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService, Tabs } from '@lightning/wild-ui';
import { finalize  } from 'rxjs';

@Component({
    selector: 'app-devices-nfc-tag-location-create',
    templateUrl: './devices-nfc-tag-location-create.component.html',
    styleUrls: ['./devices-nfc-tag-location-create.component.scss'],
})
export class DevicesNfcTagLocationCreateComponent {

    public GoundLocations = GroundLocations;

    @Input()
    public resolve = () => Function;

    @Input()
    public title = '';

    public tabs: Tabs = {
        items: [
            { selector: 'instructions', text: 'instructions' },
            { selector: 'serial',       text: 'serial' },
            { selector: 'location',     text: 'location' },
            { selector: 'write',        text: 'write' },
            { selector: 'complete',     text: 'complete' },
        ]};

    public request = new DeviceCreateNfcTagLocationRequest();

    public isLoading = false;

    public nfcTag: DeviceNfcTags | undefined;

    public constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private workBoardService: WorkBoardService) {

            this.tabs.selected = this.tabs.items[0];

            this.request.version = '1.0.0';
        }

    public back(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex <= 0) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex - 1];
    }

    public continue(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex >= this.tabs.items.length) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex + 1];
    }

    public get header(): string {

        let text = '';

        if (this.request.serial) {
            text += `Serial ${this.request.serial}.`;
        }

        if (this.request.location) {
            text += `\nLocation ${this.request.location}.`;
        }

        return text || `New location tag`;
    }

    public async readNfcTagUid(): Promise<void> {

        if (!this.workBoardService.isConnected) {

            await this.workBoardService.connect();
        }

        // Form autofilling
        this.request.serial = (await this.workBoardService.readNfcTagUid()).uid;
    }



    public createNfcTagAndContinue(): void {

        this.isLoading = true;

        this.onlineService.createDeviceNfcTagLocation(this.request)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: nfcTag => {

                    this.nfcTag = nfcTag;

                    this.continue();
                }
        });
    }

    public async writeNfcTagAndContinue(): Promise<void> {

        if (!this.nfcTag) {
            return;
        }

        this.isLoading = true;

        try {

            await this.workBoardService.writeNfcTag(this.nfcTag.serial, this.nfcTag.records);

            this.overlayService.openNotification({
                message: `The location tag serial ${this.nfcTag.serial} is now ready to use!`,
                type: 'success'
            });

            // TODO: Request the backend to inform the tag was written, without that, the process is not totally complete (write failure)

            this.continue();

        } catch(exception) {

            this.overlayService.openNotification({
                message: exception as string,
                type: 'error'
            });
        }

        this.isLoading = false;
    }

    public close(): void {

        this.resolve();

        this.overlayService.closeModal();
    }

    public again(): void {

        this.request = new DeviceCreateNfcTagLocationRequest();

        this.nfcTag = undefined;

        this.tabs.selected = this.tabs.items[1];
    }

}

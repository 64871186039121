import { Component, Input } from '@angular/core';

import { Account, AccountStatus } from '@lightning/lightning-definitions';
import { OverlayService } from '@lightning/wild-ui';

import { NfcTagData } from '@lightning/backoffice/app/shared/services/work-board/work-board.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-device-read',
    templateUrl: './device-read.component.html',
    styleUrls: ['./device-read.component.scss'],
})
export class DeviceReadComponent {

    public AccountStatus = AccountStatus;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: Account | null = null) => { /* */ };

    @Input()
    public title = '';

    @Input()
    public nfcData = new NfcTagData();

    public constructor(
        private router: Router,
        private overlayService: OverlayService) {}

    public close(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }

    public search(): void {

        this.router.navigate(['devices'], { queryParams: { serial: this.nfcData.uid }});

        this.resolve(null);

        this.overlayService.closeModal();
    }
}

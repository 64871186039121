import { Component, Input } from '@angular/core';
import { ProductsWithSoftware, StoreOrder, StoreOrderItem, StoreOrderStatus, StoreOrderUpdateStatusResponse } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { DevicesNfcTagOperatorCreateComponent } from '../../../devices/components/devices-nfc-tag-operator-create/devices-nfc-tag-operator-create.component';

@Component({
    selector: 'app-store-orders-edit',
    templateUrl: './store-orders-edit.component.html',
    styleUrls: ['./store-orders-edit.component.scss'],
})
export class StoreOrdersEditComponent {

    public Products = ProductsWithSoftware;

    public Status = StoreOrderStatus;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: StoreOrder | null = null) => Function;

    @Input()
    public title = '';

    @Input()
    public order = new StoreOrder();

    public newOrderStatus!: StoreOrderStatus;

    public constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService) {

        this.newOrderStatus = this.order.status;
    }


    public updateStatus(status: StoreOrderStatus): void {

        if (status === this.order.status) {
            return;
        }

        const previousStatus: StoreOrderStatus = this.order.status;

        this.onlineService.updateStoreOrderStatus(this.order.id, status).subscribe({
            next: (response: StoreOrderUpdateStatusResponse) => {
                this.order.status = response.status;
            },
            error: () => {

                // Change detection suxx here :s

                this.order.status = status;

                setTimeout(() => {
                    this.order.status = previousStatus;
                }, 0);
            }
        });
    }

    public createNfcTagOperator(item: StoreOrderItem): void {

        this.overlayService.closeModal();

        const operatorName = item.options
            .filter(option => option.name === 'operatorName')[0]?.value;

        const operatorIsLegend = item.options
            .filter(option => option.name === 'legend')[0]?.value;

        this.overlayService.openModal({
            component: DevicesNfcTagOperatorCreateComponent,
            inputs: {
                title: 'new operator tag',
                orderId: this.order.id,
                accountId: this.order.accountId,
                operatorName,
                operatorIsLegend
            }
        });
    }

    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { FirmwaresComponent } from './components/firmwares/firmwares.component';
import { FirmwaresEditComponent } from './components/firmwares-edit/firmwares-edit.component';

@NgModule({
  declarations: [
    FirmwaresComponent,
    FirmwaresEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
})
export class FirmwaresModule {}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent  {

    @Input()
    public description = '';

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: boolean | null = null) => { /* */ };

    constructor(private overlayService: OverlayService) { }

    @HostListener('document:keyup.escape', ['$event'])
    public cancel(): void {

        this.resolve(false);

        this.overlayService.closeModal();
    }

    @HostListener('document:keyup.enter', ['$event'])
    public confirm(): void {

        this.resolve(true);

        this.overlayService.closeModal();
    }
}

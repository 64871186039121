import { Component } from '@angular/core';

import { OverlayService } from '@lightning/wild-ui';

import { DevicesNfcTagOperatorCreateComponent } from '../devices-nfc-tag-operator-create/devices-nfc-tag-operator-create.component';
import { DevicesNfcTagGuestCreateComponent } from '../devices-nfc-tag-guest-create/devices-nfc-tag-guest-create.component';
import { DevicesNfcTagResourceCreateComponent } from '../devices-nfc-tag-resource-create/devices-nfc-tag-resource-create.component';
import { DevicesNfcTagPartCreateComponent } from '../devices-nfc-tag-part-create/devices-nfc-tag-part-create.component';
import { DevicesNfcTagLocationCreateComponent } from '../devices-nfc-tag-location/devices-nfc-tag-location-create.component';
import { DevicesNfcTagSystemCreateComponent } from '../devices-nfc-tag-system-create/devices-nfc-tag-system-create.component';
import { DevicesGroundModuleCreateComponent } from '../devices-ground-module-create/devices-ground-module-create.component';

@Component({
    selector: 'app-devices-create',
    templateUrl: './devices-create.component.html',
    styleUrls: ['./devices-create.component.scss'],
})
export class DevicesCreateComponent {

    constructor(private overlayService: OverlayService) { }

    public async createNfcTagOperator(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesNfcTagOperatorCreateComponent,
            inputs: {
                title: 'new operator tag'
            }
        });
    }

    public async createNfcTagGuest(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesNfcTagGuestCreateComponent,
            inputs: {
                title: 'new guest tag'
            }
        });
    }

    public async createNfcTagResource(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesNfcTagResourceCreateComponent,
            inputs: {
                title: 'new resource tag'
            }
        });
    }

    public async createNfcTagPart(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesNfcTagPartCreateComponent,
            inputs: {
                title: 'new part tag'
            }
        });
    }

    public async createNfcTagLocation(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesNfcTagLocationCreateComponent,
            inputs: {
                title: 'new location tag'
            }
        });
    }

    public async createNfcTagSystem(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesNfcTagSystemCreateComponent,
            inputs: {
                title: 'new system tag'
            }
        });
    }

    public async createGroundModule(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesGroundModuleCreateComponent,
            inputs: {
                title: 'new ground module'
            }
        });
    }


}

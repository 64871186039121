<wui-modal-layout [wuiDisabled]="isLoading">

    <div title>{{ title }}</div>

    <!-- Instructions -------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'instructions'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="instructions">
            Please follow these instructions:
            <ul>
                <li>Take a device you want to get information.</li>
                <li>Put it on the work board.</li>
                <li>Press Continue.</li>
            </ul>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'instructions'">
        <wui-button (click)="quit()">cancel</wui-button>
        <wui-button (click)="readNfcTagUid(); continue()">continue</wui-button>
    </wui-buttons>

    <!-- Serial ------------------------------------------------------------------------------------------------>

    <div body *ngIf="tabs.selected?.selector === 'serial'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="tag">
            <wui-entry>
                <div name>Serial</div>
                <wui-input value [(value)]="request.serial" [required]="true" (click)="readNfcTagUid()"></wui-input>
                <div detail>Press 'read serial' to autofill this field.</div>
            </wui-entry>
        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'serial'">
        <wui-button (click)="back()">back</wui-button>
        <wui-button (click)="getDeviceAndContinue()" [wuiDisabled]="!request.serial">continue</wui-button>
    </wui-buttons>


    <!-- Device ----------------------------------------------------------------------------------------------->

    <div body *ngIf="tabs.selected?.selector === 'device'">

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        <wui-section name="device">

            <wui-entry>
                <div name>Id</div>
                <div value>{{ device?.id }}</div>
            </wui-entry>

            <wui-entry>
                <div name>Serial</div>
                <div value>{{ device?.serial }}</div>
            </wui-entry>

            <wui-entry>
                <div name>Product</div>
                <div value>{{ device?.product }}</div>
            </wui-entry>

            <wui-entry>
                <div name>Model</div>
                <div value>{{ device?.model || '?'}}</div>
            </wui-entry>

            <wui-entry>
                <div name>Version</div>
                <div value>{{ device?.version || '?' }}</div>
            </wui-entry>

            <wui-entry>
                <div name>Created</div>
                <div value>{{ device?.created | date }}</div>
            </wui-entry>

            <wui-entry>
                <div name>Details</div>
                <wui-json value [value]="device?.details"></wui-json>
            </wui-entry>


        </wui-section>

    </div>
    <wui-buttons footer *ngIf="tabs.selected?.selector === 'device'">
        <wui-button
            [wuiDisabled]="isDeleted"
            (click)="deleteDeviceRegistration()">
            unregister
        </wui-button>
        <wui-button (click)="quit()">
            quit
        </wui-button>
    </wui-buttons>

</wui-modal-layout>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsComponent } from './components/accounts/accounts.component';
import { SharedModule } from '../../shared/shared.module';
import { AccountsEditComponent } from './components/accounts-edit/accounts-edit.component';


@NgModule({
    declarations: [
        AccountsComponent,
        AccountsEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
    ]
})
export class AccountsModule { }

import { apps } from '@lightning/configuration';

export const environment = {
    production: false,
    apps,

    sentry: {
        url: 'https://morgan-wild.sentry.io/projects/'
    },

    workBoard: {
        fieldsSeparator: '\t',
        framesSeparator: '\n',
        serial: {
            devicesFilters: [
                { usbVendorId: 0x1A86 },    // CH340:   https://devicehunt.com/view/type/usb/vendor/1A86/device/7523
                { usbVendorId: 0x10C4 }     // CP210x:  https://devicehunt.com/view/type/usb/vendor/10C4/device/8477
            ],
            options: {
                baudRate: 19200,            // 115200
                dataBits: 8,
                parity: 'none',
                bufferSize: 512,
                flowControl: 'none'
            } as SerialOptions
        }
    }
};

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { faMagnifyingGlass, faWindowMaximize, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalConfirmComponent } from '@lightning/backoffice/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalTableFilterComponent } from '@lightning/backoffice/app/shared/components/modal-table-filter/modal-table-filter.component';
import { FiltersOptions } from '@lightning/backoffice/app/shared/models/table-filters-options.types';
import { TableLine } from '@lightning/backoffice/app/shared/models/table-line.types';
import { AppsUtils } from '@lightning/configuration';
import { OperatorProfile, OperatorsSearchRequest, OperatorsSearchResponse, NfcTagsModels, OperatorStatus } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { KeyValuePipe, OverlayService } from '@lightning/wild-ui';

import { OperatorsEditComponent } from '../operators-edit/operators-edit.component';



@Component({
    selector: 'app-operators',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {

    public icons = {
        search: faMagnifyingGlass,
        view: faWindowMaximize,
        delete: faXmarkCircle
    };

    public lines: Array<TableLine<OperatorProfile>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private keyValuePipe: KeyValuePipe) { }

    ngOnInit(): void {

        this.filters = structuredClone(this.route.snapshot.queryParams);

        this.load(false);
    }

    public load(next: boolean): void {


        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request: OperatorsSearchRequest = {
            index: next ? this.lines.length : 0,
            limit: 50,
            ...this.filters
        }

        this.onlineService
            .searchOperators(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: OperatorsSearchResponse) => {

                    const lines: Array<TableLine<OperatorProfile>> = response.items.map((operatorProfile) => {

                        return {
                            isSelected: false,
                            data: operatorProfile,
                            calculated: {
                                hasUntrackedtags: operatorProfile.nfcTags.find(tag => !tag.serial)
                            }
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        const options: FiltersOptions = {
            description: 'search by ' + filter,
            type: 'text',
            choices: [],
            value: this.filters[filter]
        };

        switch(filter) {

            case 'number':
                options.type = 'number';
            break;

            case 'status':
                options.choices = this.keyValuePipe.transform(OperatorStatus);
            break;

        }

        // Selection modal
        const value: string | undefined = await this.overlayService.openModal({
            component: ModalTableFilterComponent,
            inputs: {
                options
            }
        });

        // Canceled
        if(value === undefined) {
            return;
        }

        if(value === '') {
            delete this.filters[filter];
        } else {
            this.filters[filter] = value;
        }

        this.load(false);

        // Update the url according the filter
        this.router.navigate([], { queryParams: this.filters });
    }

    public view(id: string) {
        window.open(`${AppsUtils.resolveAppUrl('website')}/operators/${id}`, `_blank`);
    }

    public async edit(operator: OperatorProfile) {

        const updatedOperator = await this.overlayService.openModal({
            component: OperatorsEditComponent,
            inputs: {
                title: `Operator ${operator.number}: ${operator.name}`,
                operator
            }
        });

        if (updatedOperator) {
            this.load(false);
        }
    }

    public async delete(id: string) {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                title: `Operator delete`,
                description: `Are you sure you want to remove the operator profile ${id}?`
            }})) {

            this.onlineService.deleteOperator(id).subscribe({
                next: () => {
                    this.lines = this.lines.filter(line => line.data.id !== id);
                }
            })
        }

    }

}

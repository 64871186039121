import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-operations-photographies',
    templateUrl: './operations-photographies.component.html',
    styleUrls: ['./operations-photographies.component.scss'],
})
export class OperationsPhotographiesComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}

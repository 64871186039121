<div class="metric"
    wuiTacticalCorners
    [wuiTacticalCornersOptions]="{ disappear: false }">
    <div class="title">accounts</div>
    <div class="total">{{ dashboard.accounts.total }}</div>
    <!-- According the enum -->
    <wui-entry *ngFor="let status of AccountStatus | keyValue">
        <div name>{{ status.key }}</div>
        <div value>{{ dashboard.accounts.statuses[status.value] || 0 }} / {{ dashboard.accounts.total }}</div>
    </wui-entry>
</div>

<div class="metric"
    wuiTacticalCorners
    [wuiTacticalCornersOptions]="{ disappear: false }">
    <div class="title">operators</div>
    <div class="total">{{ dashboard.operators.total }}</div>
    <wui-entry>
        <div name>Legends</div>
        <div value>{{ dashboard.operators.legends }} / {{ dashboard.operators.total }}</div>
    </wui-entry>
    <wui-entry>
        <div name>Classified</div>
        <div value>{{ dashboard.operators.statuses[OperatorStatus.Classified] || 0}} / {{ dashboard.operators.total }}</div>
    </wui-entry>
    <!-- <wui-graph-bar [items]="operatorsStatusGraphItems" (clickItem)="clickOperatorStatusGraphItem($event)"></wui-graph-bar> -->
    <wui-graph-bar [items]="operatorsLegendsGraphItems" (clickItem)="clickOperatorLegendGraphItem($event)"></wui-graph-bar>
</div>

<div class="metric"
    wuiTacticalCorners
    [wuiTacticalCornersOptions]="{ disappear: false }">
    <div class="title">operations reports</div>
    <div class="total">{{ dashboard.operations.reports.total }}</div>
    <wui-graph-bar [items]="operationsStatusGraphItems" (clickItem)="clickOperationReportModeGraphItem($event)"></wui-graph-bar>
</div>

<div class="metric"
    wuiTacticalCorners
    [wuiTacticalCornersOptions]="{ disappear: false }">
    <div class="title">operations photographies</div>
    <div class="total">{{ dashboard.operations.photographies.total }}</div>
</div>

<div class="metric"
    wuiTacticalCorners
    [wuiTacticalCornersOptions]="{ disappear: false }"> <!-- TODO Use infinite in case of warning (pending order for example)-->
    <div class="title">orders</div>
    <div class="total">{{ dashboard.store.orders.total }}</div>

    <!-- According the enum -->
    <wui-entry *ngFor="let status of StoreOrderStatus | keyValue">
        <div name>{{ status.key }}</div>
        <div value>{{ dashboard.store.orders.statuses[status.value] || 0 }} / {{ dashboard.store.orders.total }}</div>
    </wui-entry>

    <!-- <wui-entry>
        <div name>paid</div>
        <div value>{{ dashboard.store.orders.statuses[StoreOrderStatus.Paid] || 0 }}</div>
    </wui-entry>
    <wui-entry>
        <div name>paid on delivery</div>
        <div value>{{ dashboard.store.orders.statuses[StoreOrderStatus.PayOnDelivery] || 0 }}</div>
    </wui-entry>
    <wui-entry>
        <div name>payment failed</div>
        <div value>{{ dashboard.store.orders.statuses[StoreOrderStatus.PaymentFailed] || 0 }}</div>
    </wui-entry> -->
    <wui-graph-bar [items]="storeOrdersStatusGraphItems" (clickItem)="clickStoreOrderStatusGraphItem($event)"></wui-graph-bar>
</div>

<div class="metric"
    wuiTacticalCorners
    [wuiTacticalCornersOptions]="{ disappear: false }">
    <div class="title">registered devices</div>
    <div class="total">{{ dashboard.devices.total }}</div>

    <!-- According the result (database) -->
    <wui-entry *ngFor="let count of dashboard.devices.products | keyValue">
        <div name>{{ count.key }}</div>
        <div value>{{ count.value }}</div>
    </wui-entry>
</div>




<!-- <wui-section name="operators">
    <p>
        Total {{ dashboard.operators.total }}
    </p>
    <wui-graph-bar [items]="operatorsStatusGraphItems" (clickItem)="clickOperatorStatusGraphItem($event)"></wui-graph-bar>

</wui-section>
<wui-section>
    <p>
        Legends {{ dashboard.operators.legends }}
    </p>
    <wui-graph-bar [items]="operatorsLegendsGraphItems" (clickItem)="clickOperatorLegendGraphItem($event)"></wui-graph-bar>
</wui-section>

<wui-section name="operations">
    <p>
        Total {{ dashboard.operations.total }}
    </p>
    <wui-graph-bar [items]="operationsStatusGraphItems" (clickItem)="clickOperationStatusGraphItem($event)"></wui-graph-bar>
</wui-section>

<wui-section name="store orders">
    <p>
        Total {{ dashboard.store.orders.total }}
    </p>
    <wui-graph-bar [items]="storeOrdersStatusGraphItems" (clickItem)="clickStoreOrderStatusGraphItem($event)"></wui-graph-bar>
</wui-section>

<wui-section name="raw">
    <wui-json [value]="dashboard"></wui-json>
</wui-section> -->

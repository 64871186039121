import { EventEmitter, Injectable } from '@angular/core';
import { WorkBoardConnector } from './connectors/connector.interface';
import { WebSerialworkBoardConnector } from './connectors/web-serial.connector';
import { environment } from '@lightning/backoffice/environments/environment';

export class NfcTagData {

    public uid = '';

    public records = new Array<string>();
}

@Injectable({
    providedIn: 'root'
})
export class WorkBoardService {

    private connector: WorkBoardConnector;

    private onReceiveEvent = new EventEmitter<{type: string, content: any}>();

    constructor() {

        this.connector = new WebSerialworkBoardConnector();
        this.connector.onReceive.subscribe({ next: (data: string) => this.onReceive(data)});
    }

    public async connect(): Promise<void> {

        return this.connector.connect();
    }

    public async disconnect(): Promise<void> {

        return this.connector.disconnect();
    }

    public get isConnected(): boolean {

        return this.connector.isConnected;
    }

    public async readNfcTagUid(): Promise<{ uid: string }> {

        await this.connector.send('readNfcTagUid');

        return await this.getAsyncResult('readNfcTagUid');

    }

    public async readNfcTagRecords(): Promise<Array<string>> {

        await this.connector.send('readNfcTagRecords');

        const result = await this.getAsyncResult('readNfcTagRecords');

        return result.records;
    }

    public async readNfcTag(): Promise<NfcTagData> {

        // TODO: Join them into one single command ?
        const data =  {
            ...await this.readNfcTagUid(),
            records: await this.readNfcTagRecords()
        };

        return data;
    }

    public async writeNfcTag(serial: string, records: Array<string>): Promise<void> {

        let fields: Array<string> = [];

        fields.push('writeNfcTag');
        fields.push('0');
        fields.push(serial);

        fields = fields.concat(records);

        await this.connector.send(fields.join(environment.workBoard.fieldsSeparator));

        await this.getAsyncResult('writeNfcTag');
    }





    private onReceive(data: string): void {

        console.log(`WorkBoardService receive ${data}`);

        const type = data.substring(0, data.indexOf(' '));

        try {
            const content = JSON.parse(data.substring(data.indexOf(' ') + 1));

            this.onReceiveEvent.emit({ type, content });

        } catch(exception) {

            console.warn(`WorkBoardService: Invalid JSON data received: ${data}`);
        }
    }

    private getAsyncResult(type: string/*, timeout = 0*/): Promise<any> {

        return new Promise((resolve, reject) => {

            const subscription = this.onReceiveEvent.subscribe({
                next: (frame: {type: string, content: any}) => {

                    if (frame.type !== type) {
                        return;
                    }

                    if (frame.content.success) {

                        subscription.unsubscribe();

                        resolve(frame.content.success);
                    }

                    if (frame.content.error) {

                        subscription.unsubscribe();

                        reject(frame.content.error.message);
                    }
                }
            });

        });

    }
}

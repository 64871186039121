import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { faMagnifyingGlass, faWindowMaximize, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalConfirmComponent } from '@lightning/backoffice/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalTableFilterComponent } from '@lightning/backoffice/app/shared/components/modal-table-filter/modal-table-filter.component';
import { FiltersOptions } from '@lightning/backoffice/app/shared/models/table-filters-options.types';
import { TableLine } from '@lightning/backoffice/app/shared/models/table-line.types';
import { StringToColorUtils } from '@lightning/backoffice/app/shared/utils/string-to-color.utils';
import { Firmware, FirmwaresSearchRequest, FirmwaresSearchResponse, SementicVersionUtils } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { environment } from '@lightning/backoffice/environments/environment';

import { FirmwaresEditComponent } from '../firmwares-edit/firmwares-edit.component';



@Component({
    selector: 'app-firmwares',
    templateUrl: './firmwares.component.html',
    styleUrls: ['./firmwares.component.scss']
})
export class FirmwaresComponent implements OnInit {

    public environment = environment;

    public icons = {
        search: faMagnifyingGlass,
        view: faWindowMaximize,
        delete: faXmarkCircle
    };

    public lines: Array<TableLine<Firmware>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        this.filters = structuredClone(this.route.snapshot.queryParams);

        this.load(false);
    }

    public load(next: boolean): void {

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request: FirmwaresSearchRequest = {
            index: next ? this.lines.length : 0,
            limit: 50,
            ...this.filters
        }

        this.onlineService
            .searchFirmwares(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: FirmwaresSearchResponse) => {

                    const lines: Array<TableLine<Firmware>> = response.items.map((firmware) => {

                        return {
                            isSelected: false,
                            data: firmware,
                            calculated: {
                                firmwareColor:
                                    StringToColorUtils.Convert(SementicVersionUtils.toString(firmware.version), { brightness: 'light' }),
                                targetColor:
                                    StringToColorUtils.Convert(firmware.target.product + SementicVersionUtils.toString(firmware.target.hardwareVersion), { brightness: 'light' }),
                            }
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        const options: FiltersOptions = {
            description: 'search by ' + filter,
            type: 'text',
            choices: [],
            value: this.filters[filter]
        };

        switch(filter) {

            case 'number':
                options.type = 'number';
            break;

        }

        // Selection modal
        const value: string | undefined = await this.overlayService.openModal({
            component: ModalTableFilterComponent,
            inputs: {
                options
            }
        });

        // Canceled
        if(value === undefined) {
            return;
        }

        if(value === '') {
            delete this.filters[filter];
        } else {
            this.filters[filter] = value;
        }

        this.load(false);

        // Update the url according the filter
        this.router.navigate([], { queryParams: this.filters });
    }

    public async edit(firmware: Firmware) {

        const updatedFirmware = await this.overlayService.openModal({
            component: FirmwaresEditComponent,
            inputs: {
                title: `Firmware ${firmware.id}`,
                firmware
            }
        });

        if (updatedFirmware) {
            this.load(false);
        }
    }

    public async create() {

        const firmware = await this.overlayService.openModal({
            component: FirmwaresEditComponent,
            inputs: {
                title: `New firmware`
            }
        });

        if (firmware) {
            this.load(false);
        }
    }

    public async delete(id: string) {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                title: `Are you sure you want to remove the firmware ${id}?`
            }})) {

            this.onlineService.deleteFirmware(id).subscribe({
                next: () => {
                    this.lines = this.lines.filter(line => line.data.id !== id);
                }
            })
        }

    }

}

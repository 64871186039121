<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="data">
            <wui-entry>
                <div name>Uid</div>
                <div value>
                    {{ nfcData.uid }}
                </div>
            </wui-entry>
            <wui-entry *ngFor="let record of nfcData.records; let index = index">
                <div name>Record {{ index }}</div>
                <div value>
                    {{ record }}
                </div>
            </wui-entry>

        </wui-section>

        <!-- <wui-section name="raw">
            <wui-json [value]="nfcData"></wui-json>
        </wui-section> -->

    </div>

    <wui-buttons footer>
        <wui-button (click)="close()">close</wui-button>
        <wui-button (click)="search()">search</wui-button>
    </wui-buttons>

</wui-modal-layout>

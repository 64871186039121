import { Component, Input } from '@angular/core';
import { WorkBoardService } from '@lightning/backoffice/app/shared/services/work-board/work-board.service';
import { DeviceRegistration, DevicesRegistrationsSearchRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService, Tabs } from '@lightning/wild-ui';
import { finalize  } from 'rxjs';

@Component({
    selector: 'app-devices-get',
    templateUrl: './devices-get.component.html',
    styleUrls: ['./devices-get.component.scss'],
})
export class DevicesGetComponent {

    @Input()
    public resolve = () => Function;

    @Input()
    public title = '';

    public tabs: Tabs = {
        items: [
            { selector: 'instructions', text: 'instructions' },
            { selector: 'serial',       text: 'serial' },
            { selector: 'device',       text: 'device' },
        ]};

    public request = new DevicesRegistrationsSearchRequest();

    public isLoading = false;

    public isDeleted = false;

    public device: DeviceRegistration | undefined;

    public constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private workBoardService: WorkBoardService) {

            this.tabs.selected = this.tabs.items[1]; // INSTRUCTIONS SKIPPED
        }

    public back(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex <= 0) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex - 1];
    }

    public continue(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex >= this.tabs.items.length) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex + 1];
    }


    public async readNfcTagUid(): Promise<void> {

        if (!this.workBoardService.isConnected) {

            await this.workBoardService.connect();
        }

        // Form autofilling
        this.request.serial = (await this.workBoardService.readNfcTagUid()).uid;
    }


    public getDeviceAndContinue(): void {

        this.isLoading = true;

        this.onlineService.searchDevicesRegistrations(this.request)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: response => {

                    if (response.items.length === 0) {

                        this.overlayService.openNotification({
                            message: `Unable to find this device.`,
                            type: 'warning'
                        });

                        return;
                    }

                    this.device = response.items[0];

                    this.continue();
                }
        });
    }

    public deleteDeviceRegistration(): void {

        this.isLoading = true;

        this.onlineService.deleteDeviceRegistration(this.device?.id)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: () => {

                    this.isDeleted = true;

                    this.overlayService.openNotification({
                        message: `The registration of the device id ${this.device?.id} has been deleted.`,
                        type: 'success'
                    });

                }
        });
    }

    public quit(): void {

        this.resolve();

        this.overlayService.closeModal();
    }

}

<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="description">
            <wui-entry>
                <div name>Id</div>
                <div value>
                    {{ account.id }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Name</div>
                <div value>
                    {{ account.name }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Email</div>
                <div value>
                    {{ account.email }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Status</div>
                <div value
                    [class.error]="account.status !== AccountStatus.Active">
                    {{ account.status | capitalize }}
                </div>
            </wui-entry>

        </wui-section>

        <wui-section *ngFor="let address of account.addresses; let index = index" name="address">
            <wui-entry>
                <div name>{{ 'shared.firstName' | translate | capitalize }}</div>
                <div value>{{ address.firstName }}</div>
            </wui-entry>
            <wui-entry>
                <div name>{{ 'shared.lastName' | translate | capitalize }}</div>
                <div value>{{ address.lastName }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.street' | translate | capitalize }}</div>
                <div value>{{ address.street }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.postalCode' | translate | capitalize }}</div>
                <div value>{{ address.postalCode }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.city' | translate | capitalize }}</div>
                <div value>{{ address.city }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.country' | translate | capitalize }}</div>
                <div value>{{ address.country }}</div>
            </wui-entry>

            <!-- <wui-entry>
                <div name></div>
                <wui-button value (click)="accountService.editAddress">{{ 'shared.edit' | translate }}</wui-button>
            </wui-entry>

            <wui-entry>
                <div name></div>
                <wui-button value (click)="accountService.removeAddress(address)">{{ 'shared.remove' | translate }}</wui-button>
            </wui-entry> -->

        </wui-section>

        <wui-section name="raw">
            <wui-json [value]="account"></wui-json>
        </wui-section>

    </div>



    <wui-buttons footer>
        <wui-button (click)="cancel()">cancel</wui-button>
        <!-- <wui-button (click)="confirm()" [wuiDisabled]="isSending">confirm</wui-button> -->
    </wui-buttons>

</wui-modal-layout>

import { Component, Input, OnInit } from '@angular/core';
import { LaserService } from '@lightning/backoffice/app/shared/services/laser/laser.service';
import { WorkboardData, WorkBoardService } from '@lightning/backoffice/app/shared/services/work-board/work-board.service';
import { DeviceCreateGroundModuleRequest, DeviceRegistration, Products } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService, StringUtils, Tabs } from '@lightning/wild-ui';
import { finalize } from 'rxjs';


@Component({
    selector: 'app-devices-ground-module-create',
    templateUrl: './devices-ground-module-create.component.html',
    styleUrls: ['./devices-ground-module-create.component.scss'],
})
export class DevicesGroundModuleCreateComponent implements OnInit {

    @Input()
    public resolve = () => Function;

    @Input()
    public title = '';

    public tabs: Tabs = {
        items: [
            { selector: 'instructions', text: 'instructions' },
            { selector: 'information',  text: 'information' },
            { selector: 'mark',         text: 'mark' },
            { selector: 'complete',     text: 'complete' },
        ]};

    public request = new DeviceCreateGroundModuleRequest();

    public isLoading = false;

    public device?: DeviceRegistration;

    public constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private workBoardService: WorkBoardService,
        private laserService: LaserService) {

            this.tabs.selected = this.tabs.items[0];
        }

    public ngOnInit(): void {

        this.workBoardService.onReceiveEvent.subscribe({
            next: (data: WorkboardData) => {

                if (data.type !== 'lora') {
                    return;
                }

                if (this.request.serial === '') {
                    this.request.serial = data.content.senderId;
                }

                if (data.content.payload?.startsWith('state')) {

                    const fields = data.content.payload.split('|'); // state compact payload example : state|1158|GroundModule|2.0.1|0.0.0|75|083A8D8F3B10"

                    this.request = {
                        serial: data.content.senderId,
                        version: fields[3]
                    };

                }
            }
        });

        this.workBoardService.connect();
    }

    public getLaserMarkingProject(): void {

        this.laserService.getLaserMarkingProject(StringUtils.toKebabCase(Products.GroundModule), this.request);
    }

    public back(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex <= 0) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex - 1];
    }

    public continue(): void {

        if (this.tabs.selected === undefined) {

            return;
        }

        const currentTabIndex = this.tabs.items.indexOf(this.tabs.selected);

        if (currentTabIndex >= this.tabs.items.length) {
            return;
        }

        this.tabs.selected = this.tabs.items[currentTabIndex + 1];
    }

    public get header(): string {

        let text = ``;

        if (this.request.serial) {
            text += `Serial ${this.request.serial}. `;
        }

        if (this.request.version) {
            text += `Hardware version ${this.request.version}. `;
        }

        return text;
    }

    public createDeviceAndContinue(): void {

        this.isLoading = true;

        this.onlineService.createDeviceGroundModule(this.request)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: device => {

                    this.device = device;

                    console.log(this.device);

                    this.continue();
                }
        });
    }

    public close(): void {

        this.resolve();

        this.overlayService.closeModal();
    }

    public again(): void {

        this.request = new DeviceCreateGroundModuleRequest();

        this.device = undefined;

        this.tabs.selected = this.tabs.items[1];

    }

}

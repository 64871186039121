import { NfcTagsModels } from '../enums/nfc-tags.enums';
import { SementicVersion } from './sementic-version.entity';

export class NfcTag {

    public serial!: string;

    public model!: NfcTagsModels;

    public expiration?: Date;
}



export class DeviceNfcTags {

    public id = '';

    public serial = '';

    public model?: NfcTagsModels;

    public version?: SementicVersion;

    public records: Array<string> = [];

}

<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                created
                <fa-icon
                    [class.active]="filters['created'] !== undefined"
                    (click)="searchBy('created')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                product
                <fa-icon
                    [class.active]="filters['product'] !== undefined"
                    (click)="searchBy('product')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                serial
                <fa-icon
                    [class.active]="filters['serial'] !== undefined"
                    (click)="searchBy('serial')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                model
                <fa-icon
                    [class.active]="filters['model'] !== undefined"
                    (click)="searchBy('model')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                version
                <fa-icon
                    [class.active]="filters['version'] !== undefined"
                    (click)="searchBy('version')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                details
            </td>

        </tr>

        <tr *ngFor="let line of lines; let i = index"> <!---(click)="edit(line.data)">-->

            <td>{{ i + 1 }}</td>

            <td>{{ line.data.id }}</td>

            <td>{{ line.data.created | date }}</td>

            <td>{{ line.data.product }}</td>

            <td>{{ line.data.serial }}</td>

            <td>{{ line.data.model }}</td>

            <td>{{ line.data.version.major }}.{{ line.data.version.minor }}.{{ line.data.version.patch }}</td>

            <td [title]="line.data.details | json">{{ line.data.details | json }}</td>

            <td wuiStopPropagation>
                <!-- <fa-icon (click)="view(line.data.id)" [icon]="icons.view"></fa-icon> -->
                <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
            </td>

        </tr>

    </table>

</div>

import { Component, Input, OnInit } from '@angular/core';
import { OperatorAvatarName, OperatorProfile, OperatorStatus } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-operators-edit',
    templateUrl: './operators-edit.component.html',
    styleUrls: ['./operators-edit.component.scss'],
})
export class OperatorsEditComponent implements OnInit {

    public OperatorStatus = OperatorStatus;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: OperatorProfile | null = null) => Function;

    @Input()
    public title = '';

    @Input()
    public operator = new OperatorProfile();

    public images = {
        neutral: '',
        winning: '',
        losing: ''
    };

    public constructor(private overlayService: OverlayService, private onlineService: OnlineService) {}

    public ngOnInit() {

        this.images = {
            neutral: `${this.onlineService.backendUrl}/operators/${this.operator.id}/avatars/${OperatorAvatarName.Neutral}?ignoreStatus=true`,
            winning: `${this.onlineService.backendUrl}/operators/${this.operator.id}/avatars/${OperatorAvatarName.Winning}?ignoreStatus=true`,
            losing:  `${this.onlineService.backendUrl}/operators/${this.operator.id}/avatars/${OperatorAvatarName.Losing}?ignoreStatus=true`,
        };
    }

    public isExpirated(date: Date | undefined): boolean {

        if (date === undefined) {
            return false;
        }

        return new Date(date).getTime() < Date.now();
    }

    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorsComponent } from './components/operators/operators.component';
import { SharedModule } from '../../shared/shared.module';
import { OperatorsEditComponent } from './components/operators-edit/operators-edit.component';

@NgModule({
    declarations: [
        OperatorsComponent,
        OperatorsEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class OperatorsModule {}
